<nav class="navbar navbar-light navbar-expand-md fixed-top scrolling-navbar bgcolor"
  style="font-family: 'Kanit', sans-serif; font-size: 1rem; ">
  <div class="container">

    <a class="navbar-brand" routerLink="/"> <img src="../../../../assets/img/icon/logo.jpg" class="rounded" height="42"
        alt="logo" style="margin-top: -5px"> </a>
    <button class="navbar-toggler" type="button" (click)="toggleNavbar()" data-toggle="collapse"
      data-target="#navbarCollapse" aria-expanded="false" aria-label="Toggle navigation"
      aria-controls="navbarSupportedContent">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01" [ngClass]="{ 'show': navbarOpen }">

      <ul class="nav navbar-nav ml-auto mr-4">
        <li class="nav-item" routerLinkActive="active ">
          <a class="nav-link" (click)="toggleNavbar()" routerLink='/home' *ngIf="globalSvc?.preferedLanguage == 'Français'">Accueil <span class="sr-only">(current)</span>
          </a>
          <a class="nav-link" (click)="toggleNavbar()" routerLink='/home'  *ngIf="globalSvc?.preferedLanguage == 'Anglais'">Home <span class="sr-only">(current)</span>
          </a>
        </li>

        <li class="nav-item " routerLinkActive="active ">
          <a class="nav-link" (click)="toggleNavbar()" routerLink="/about"  *ngIf="globalSvc?.preferedLanguage == 'Français'">A propos </a>
          <a class="nav-link" (click)="toggleNavbar()" routerLink="/about"  *ngIf="globalSvc?.preferedLanguage == 'Anglais'">About </a>
        </li>

        <li class="nav-item " routerLinkActive="active ">
          <a class="nav-link" (click)="toggleNavbar()" routerLink="/our-partners"  *ngIf="globalSvc?.preferedLanguage == 'Français'">Réseau de soins </a>
          <a class="nav-link" (click)="toggleNavbar()" routerLink="/our-partners"  *ngIf="globalSvc?.preferedLanguage == 'Anglais'">Healthcare netword </a>
        </li>


        <li class="nav-item dropdown" mdbDropdown>
          <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" *ngIf="globalSvc?.preferedLanguage == 'Français'"> Particuliers </a>
          <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" *ngIf="globalSvc?.preferedLanguage == 'Anglais'"> Individuals </a>
          <div class="dropdown-menu dropdown-primary" aria-labelledby="navbarDropdownMenuLink">

            <a class="dropdown-item" (click)="toggleNavbar()" routerLink="/the-card" *ngIf="globalSvc?.preferedLanguage == 'Français'">La carte de santé</a>
            <a class="dropdown-item" (click)="toggleNavbar()" routerLink="/the-card" *ngIf="globalSvc?.preferedLanguage == 'Anglais'">The health card</a>


            <a class="dropdown-item" (click)="toggleNavbar()" routerLink="/commander" *ngIf="globalSvc?.preferedLanguage == 'Français'">Commander une carte</a>
            <a class="dropdown-item" (click)="toggleNavbar()" routerLink="/commander" *ngIf="globalSvc?.preferedLanguage == 'Anglais'">Order a card</a>

            <a class="dropdown-item" (click)="toggleNavbar()" routerLink="/recharger" *ngIf="globalSvc?.preferedLanguage == 'Français'">Recharger la carte</a>
            <a class="dropdown-item" (click)="toggleNavbar()" routerLink="/recharger" *ngIf="globalSvc?.preferedLanguage == 'Anglais'">Recharge the card</a>

            <a class="dropdown-item" (click)="toggleNavbar()" routerLink="/insurance" *ngIf="globalSvc?.preferedLanguage == 'Français'">Assurance maladie</a>
            <a class="dropdown-item" (click)="toggleNavbar()" routerLink="/insurance" *ngIf="globalSvc?.preferedLanguage == 'Anglais'">Health insurance</a>

          </div>
        </li>

        <li class="nav-item dropdown " mdbDropdown>
          <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" *ngIf="globalSvc?.preferedLanguage == 'Français'"> Professionnels de santé </a>
          <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" *ngIf="globalSvc?.preferedLanguage == 'Anglais'"> Health professionals </a>
          <div class="dropdown-menu dropdown-primary" aria-labelledby="navbarDropdownMenuLink">

            <a class="dropdown-item" (click)="toggleNavbar()" routerLink="/reseau-de-soin" *ngIf="globalSvc?.preferedLanguage == 'Français'">Teliya professionnels</a>
            <a class="dropdown-item" (click)="toggleNavbar()" routerLink="/reseau-de-soin" *ngIf="globalSvc?.preferedLanguage == 'Anglais'">Teliya professionals</a>
            <a class="dropdown-item" (click)="toggleNavbar()" routerLink="/adhere" *ngIf="globalSvc?.preferedLanguage == 'Français'">Adhérer au réseau</a>
            <a class="dropdown-item" (click)="toggleNavbar()" routerLink="/adhere" *ngIf="globalSvc?.preferedLanguage == 'Anglais'">Join the network</a>

          </div>
        </li>

        <li class="nav-item" routerLinkActive="active ">
          <a class="nav-link" (click)="toggleNavbar()" routerLink="/contact">Contact </a>
        </li>
        <li class="nav-item dropdown " mdbDropdown>
          <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <img
            src="../../../../assets/img/{{selectedFlag}}" [alt]="altCountry" width="18" height="12"><span
            class="sr-only"> Français</span></a>
          <div class="dropdown-menu dropdown-primary" aria-labelledby="navbarDropdownMenuLink">

            <a class="dropdown-item" (click)="onChangeLanguage(country1)">
              <img
                src="../../../../assets/img/{{countryFlag}}" [alt]="altCountry"
                width="18" height="12"> {{country1}}
            </a>
            <a class="dropdown-item"  (click)="onChangeLanguage(country2)">
              <img
                src="../../../../assets/img/{{countryFlag2}}" [alt]="altCountry2"
                width="18" height="12"> {{country2}}
            </a>

          </div>
        </li>

      </ul>

    </div>

  </div>
</nav>
