import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { CardOrderComponent } from './pages/card-order/card-order.component';
// import { InsuranceSubscriptionComponent } from './pages/insurance-subscription/insurance-subscription.component';
import { MapComponent } from './pages/map/map.component';
import { OmCheckStatusComponent } from './pages/om-check-status/om-check-status.component';
// import { OrderConditionsComponent } from './pages/order-conditions/order-conditions.component';
import { AboutComponent } from './views/pages/about/about.component';
import { AdhereComponent } from './views/pages/adhere/adhere.component';
import { AssurezvousComponent } from './views/pages/assurezvous/assurezvous.component';
// import { BetweenUsComponent } from './views/pages/between-us/between-us.component';
import { CheckoutComponent } from './views/pages/checkout/checkout.component';
import { ConditionsComponent } from './views/pages/conditions/conditions.component';
import { ContactComponent } from './views/pages/contact/contact.component';
import { ErrorComponent } from './views/pages/error/error.component';
// import { HealthcareNetworkComponent } from './views/pages/healthcare-network/healthcare-network.component';
import { HomeComponent } from './views/pages/home/home.component';
import { InsuranceNotifComponent } from './views/pages/insurance-notif/insurance-notif.component';
import { InsuranceComponent } from './views/pages/insurance/insurance.component';
import { LoginComponent } from './views/pages/login/login.component';
import { OfferpartComponent } from './views/pages/offerpart/offerpart.component';
import { OfferproComponent } from './views/pages/offerpro/offerpro.component';
import { OrderConfirmationComponent } from './views/pages/order-confirmation/order-confirmation.component';
import { OrderComponent } from './views/pages/order/order.component';
import { PrivacyPolicyComponent } from './views/pages/privacy-policy/privacy-policy.component';
import { RechargerComponent } from './views/pages/recharger/recharger.component';
import { RegisterComponent } from './views/pages/register/register.component';
import { SubscribeInsuranceComponent } from './views/pages/subscribe-insurance/subscribe-insurance.component';
import { VisapayComponent } from './views/pages/visapay/visapay.component';


const routes: Routes = [
  {path:'', component: HomeComponent},
  {path:'login', component:LoginComponent},
  {path:'register', component:RegisterComponent},
  {path:'home', component: HomeComponent},
  {path:'about', component: AboutComponent},
  {path:'contact', component: ContactComponent},
  {path:'conditions', component: ConditionsComponent},
  {path:'privacy-policy', component: PrivacyPolicyComponent},
  {path:'error', component: ErrorComponent},
  {path:'recharger', component: RechargerComponent},
  {path:'commander', component: OrderComponent},
  {path:'checkout', component: CheckoutComponent},
  {path:'order-confirmation', component: OrderConfirmationComponent},
  // {path:'order-simple-card', component: CardOrderComponent},
  {path:'insurance', component: InsuranceComponent},
  {path:'subscribe-to-insurance', component: SubscribeInsuranceComponent},
  {path:'subscribe-to-insurance/:id', component: SubscribeInsuranceComponent},
  {path:'insurance-request-notification', component: InsuranceNotifComponent},
  {path:'checkout/:id', component: CheckoutComponent},
  // {path: 'between-us', component: BetweenUsComponent},
  // {path: 'our-partners', component: MapComponent},
  {path: 'insured-you', component: AssurezvousComponent},
  {path: 'adhere', component: AdhereComponent},
  {path: 'the-card', component: OfferpartComponent},
  {path: 'reseau-de-soin', component: OfferproComponent},
  {path: 'our-partners', component: MapComponent},
  {path:'webpayment_notifurl', component:HomeComponent},
  {path:'webpayment_cancelurl', component:HomeComponent},
  {path:'om-transaction-status/:id', component:OmCheckStatusComponent},
  {path:'visapay', component:VisapayComponent},
  {path:'**', redirectTo:'error', pathMatch:'full'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes, {
        scrollPositionRestoration: 'enabled', // Add options right here
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
