import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import * as L from 'leaflet';
import 'mapbox-gl-leaflet';
import 'leaflet-mouse-position';
import { GlobalService } from 'src/app/services/global.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.css']
})
export class LeafletMapComponent implements OnInit {

  @ViewChild('map', {static: false})
  private mapContainer: ElementRef<HTMLElement>;
  user: any;

  pharmacies: any;
  hospitals: any;
  laboratories: any;

  lat = 9.629956;
  lng = -13.590515;
  partners: any[];

  constructor(
    private globalService?: GlobalService,
    private orderSvc?: OrderService,
  ) { }

  ngOnInit(): void {
    this.user = this.globalService.connectedUser;
    navigator.geolocation.getCurrentPosition((position) => {
      this.lat = position.coords.latitude ? position.coords.latitude: this.lat;
      this.lng = position.coords.longitude ? position.coords.longitude : this.lng;
      console.log(position.coords.latitude);
      console.log(position.coords.longitude);
    });
    this.getPartners();
  }

  ngAfterViewInit() {

    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(this.setGeoLocation.bind(this));
    // }
    navigator.geolocation.getCurrentPosition((position) => {
      this.lat = position.coords.latitude ? position.coords.latitude: this.lat;
      this.lng = position.coords.longitude ? position.coords.longitude : this.lng;
      // console.log(position.coords.latitude);
      // console.log(position.coords.longitude);
    });
    // this.setMap();
  }
  setGeoLocation(position: { coords: { latitude: any; longitude: any } }) {
    const hIcon = L.divIcon({
      className: 'custom-div-icon',
      html: '<div style=\'background-color:#1e8bc3;\' class="marker-pin"></div><i class="material-icons">local_hospital</i>',
      iconSize: [30, 42],
      iconAnchor: [15, 42]
    });
    const lIcon = L.divIcon({
      className: 'custom-div-icon',
      html: '<div style=\'background-color:green;\' class="marker-pin"></div><i class="material-icons">local_pharmacy</i>',
      iconSize: [30, 42],
      iconAnchor: [15, 42]
    });

    const h1 = L.marker([9.586289, -13.655393], { icon: hIcon}).bindPopup('This is Littleton, CO.'),
          h2    = L.marker([9.598814, -13.653332], { icon: hIcon}).bindPopup('This is Denver, CO.'),
          h3    = L.marker([9.587304, -13.602526], { icon: hIcon}).bindPopup('This is Aurora, CO.'),
          h4    = L.marker([9.665157, -13.565793], { icon: hIcon}).bindPopup('This is Golden, CO.'),
          h5    = L.marker([9.604907, -13.635824], { icon: hIcon}).bindPopup('This is Aurora, CO.'),
          h6    = L.marker([9.641126, -13.620719], { icon: hIcon}).bindPopup('This is Golden, CO.');

    this.hospitals = L.layerGroup([h1, h2, h3, h4, h5, h6]);

    const l1 = L.marker([9.69257, -13.585704], { icon: lIcon}).bindPopup('This is Littleton, CO.'),
          l2    = L.marker([9.640787, -13.616257], { icon: lIcon}).bindPopup('This is Denver, CO.'),
          l3    = L.marker([9.643157, -13.547941], { icon: lIcon}).bindPopup('This is Aurora, CO.'),
          l4    = L.marker([9.622509, -13.602525], { icon: lIcon}).bindPopup('This is Golden, CO.'),
          l5    = L.marker([9.598814, -13.653676], { icon: lIcon}).bindPopup('This is Aurora, CO.'),
          l6    = L.marker([9.58595, -13.614882], { icon: lIcon}).bindPopup('This is Golden, CO.');

    this.laboratories = L.layerGroup([l1, l2, l3, l4, l5, l6]);

    const p1 = L.marker([9.69257, -13.585704], { icon: lIcon}).bindPopup('This is Littleton, CO.'),
          p2    = L.marker([9.640787, -13.616257], { icon: lIcon}).bindPopup('This is Denver, CO.'),
          p3    = L.marker([9.643157, -13.547941], { icon: lIcon}).bindPopup('This is Aurora, CO.'),
          p4    = L.marker([9.622509, -13.602525], { icon: lIcon}).bindPopup('This is Golden, CO.'),
          p5    = L.marker([9.598814, -13.653676], { icon: lIcon}).bindPopup('This is Aurora, CO.'),
          p6    = L.marker([9.58595, -13.614882], { icon: lIcon}).bindPopup('This is Golden, CO.');

    this.pharmacies = L.layerGroup([p1, p2, p3, p4, l5, p6]);

    const overlayMaps = {
      'hospitals': this.hospitals,
      'laboratories,': this.laboratories,
      // 'pharmacies,': this.pharmacies,
    };

    const {
       coords: { latitude, longitude },
    } = position;

    // const  map = new L.Map(this.mapContainer.nativeElement).setView([latitude, longitude], 12);
    const  map = new L.Map(this.mapContainer.nativeElement,
      {
        center: [this.lat,  this.lng],
        zoom: 12,
        zoomControl: true,
        closePopupOnClick: false,
        // trackResize: true,
        layers: [this.hospitals]
      }
    );
    L.control.layers(overlayMaps).addTo(map);
    // the attribution is required for the Geoapify Free tariff plan
    map.attributionControl
      .setPrefix('')
      .addAttribution(
        this.globalService.mapAttribution
      );
    L.mapboxGL({
      style: `${this.globalService.mapStyle}?apiKey=${this.globalService.myAPIKey}`,
      accessToken: 'no-token'
    }).addTo(map);

    L.control.mousePosition().addTo(map);

    const location_ = L.marker([latitude, longitude]).addTo(map);
    location_.bindPopup('Hello <b>' + this.user.name + '.</b><br> You are here, at:<br>(' + latitude + ',' + longitude + ')' ).openPopup();

    const popup = L.popup();
    function onMapClick(e) {
        popup
            .setLatLng(e.latlng)
            .setContent('Coords: ' + e.latlng.toString())
            .openOn(map);
    }
    map.on('click', onMapClick);
  }

  setMap(position?: { coords: { latitude: any; longitude: any } }) {

    const satellite = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
                      {
                        // maxZoom: 18,
                        id: 'mapbox/satellite-streets-v11',
                        tileSize: 512,
                        zoomOffset: -1,
                        accessToken: this.globalService.geomapAccessToken,
                        attribution: this.globalService.mapAttribution
                      }),
          streets   = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
                      {
                        // maxZoom: 18,
                        id: 'mapbox/streets-v11',
                        tileSize: 512,
                        zoomOffset: -1,
                        accessToken: this.globalService.geomapAccessToken,
                        attribution: this.globalService.mapAttribution
                      });

    const baseMaps = {
                        'Vue Satellite': satellite,
                        'Vue Streets': streets
                    };
    const overlayMaps = {
                        'Clinic/Hospital': this.hospitals,
                        'Pharmacies': this.pharmacies,
                        'Laboratoires': this.laboratories
                      };
    // const latitude = position.coords.latitude, longitude = position.coords.longitude;
    const map = L.map(this.mapContainer.nativeElement,
                    {
                      // center: [9.641185, -13.578401],
                      zoom: 18,
                      layers: [streets, this.hospitals, this.pharmacies, this.laboratories]
                    });

    // const location_ = L.marker([9.6411855, -13.5784012]).addTo(map);
    // location_.bindPopup('Hello <b>' + this.user.name + '.</b><br> You are here').openPopup();

    map.locate({setView: true, maxZoom: 15});
    L.control.layers(baseMaps, overlayMaps).addTo(map);
    L.control.mousePosition().addTo(map);
    const popup = L.popup();
    function onMapClick(e) {
        // console.log(e);
        popup.setLatLng(e.latlng).setContent('Coords: ' + e.latlng.toString()).openOn(map);
    }
    map.on('click', onMapClick);

    function onLocationFound(e) {
        const radius = e.accuracy;
        // console.log(e);
        // L.marker(e.latlng).addTo(map)
        //     .bindPopup('Vous etes a ' + radius + ' metre de ce point: ' + e.latlng).openPopup();
        // let popup = L.popup()
        //     .setLatLng(e.latlng)
        //     .setContent('Vous êtes dans cette zone')
        //     .openOn(map);

        // L.circle(e.latlng, radius).addTo(map);
    }

    map.once('locationfound', onLocationFound);
  }
  getPartners(){
    let is_grab_point = false;
    this.globalService.showLoading();
    this.orderSvc.getCardGrabPoints(is_grab_point).subscribe(
      (response: any) => {
        // console.log(response['data']);
        this.partners = response['data'];
        this.pushData();
      },
      (error) => {
        // console.log(error);
      }
    );
  }
  pushData() {
    const hIcon = L.divIcon({
      className: 'custom-div-icon',
      html: '<div style=\'background-color:#1e8bc3;\' class="marker-pin"></div><i class="material-icons">local_hospital</i>',
      iconSize: [30, 42],
      iconAnchor: [15, 42]
    });
    const lIcon = L.divIcon({
      className: 'custom-div-icon',
      html: '<div style=\'background-color:green;\' class="marker-pin"></div><i class="material-icons">local_pharmacy</i>',
      iconSize: [30, 42],
      iconAnchor: [15, 42]
    });

    const pIcon = L.divIcon({
      className: 'custom-div-icon',
      html: '<div style=\'background-color:blue;\' class="marker-pin"></div><i class="material-icons">local_pharmacy</i>',
      iconSize: [30, 42],
      iconAnchor: [15, 42]
    });
    let clinic = [];
    let laboratory = [];
    let pharmacy = [];

    for (let index = 0; index < this.partners.length; index++) {
       let part = this.partners[index];
        if (part.type == 'clinic') {
          // console.log('clinic');
            if (+part.lat && +part.lng) {
              const h1 = L.marker([+part.lat, +part.lng], { icon: hIcon}).bindPopup('Clinique: '+part.name +'<br> Tel:'+part.phone+'<br> Adresse:'+part.address);
              clinic.push(h1);
            }
        }
        if (part.type == 'laboratory') {
            if (+part.lat && +part.lng) {
              const l1 = L.marker([+part.lat, +part.lng], { icon: pIcon}).bindPopup('Laboratoire: '+part.name+'<br> Tel:'+part.phone+'<br> Adresse:'+part.address);
              laboratory.push(l1);
            }
        }
        if (part.type == 'pharmacy') {
            if (+part.lat && +part.lng) {
              const p1 = L.marker([+part.lat, +part.lng], { icon: hIcon}).bindPopup('Pharmacie: '+part.name+'<br> Tel:'+part.phone+'<br> Adresse:'+part.address);
              pharmacy.push(p1);
            }
        }
        // ['clinic', 'pharmacy', 'laboratory', 'etranger'
    }
    this.hospitals = L.layerGroup(clinic);
    this.laboratories = L.layerGroup(laboratory);
    this.pharmacies = L.layerGroup(pharmacy);
    this.setMap();
  }

}
